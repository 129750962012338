import Script from "next/script";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import Link from "next/link";
import Img from "components/base/img/Img";
import styles from "../footer.module.sass";

const LicenseLogo: FC = () => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const license = COMMON_SITE_CONFIGS.license;
    const windowScriptApg = `apg_${license.apg_id?.replaceAll("-", "_")}`;
    const windowScriptAnjour = `anj_${license.anj_id?.replaceAll("-", "_")}`;

    useEffect(() => {
        if (license.apg_id && typeof window[windowScriptApg as any] !== "undefined") {
            (window[windowScriptApg as any] as any)?.init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (license.anj_id && typeof window[windowScriptAnjour as any] !== "undefined") {
            (window[windowScriptAnjour as any] as any)?.init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAntillephoneScriptLoad = useCallback(() => {
        (window[windowScriptApg as any] as any)?.init();
    }, [windowScriptApg]);

    const onAnjouanGamingScriptLoad = useCallback(() => {
        (window[windowScriptAnjour as any] as any)?.init();
    }, [windowScriptAnjour]);

    const renderApgLicense = useMemo(
        () => (
            <>
                <div
                    id={`apg-${license.apg_id}`}
                    data-apg-seal-id={license.apg_id}
                    data-apg-image-size="128"
                    data-apg-image-type="basic-small"
                />
                <Script
                    src={`https://${license.apg_id}.snippet.antillephone.com/apg-seal.js`}
                    onLoad={onAntillephoneScriptLoad}
                />
            </>
        ),
        [license, onAntillephoneScriptLoad],
    );

    const renderGcbLicense = useMemo(
        () => (
            <Link
                target="_blank"
                href={`https://cert.gcb.cw/certificate?id=${license.gcb_id}`}
                className={styles.betmidasLink}>
                <Img
                    source={`${ASSETS_URL}/common/footer/gcb_license_logo.png`}
                    alt="gcb_license"
                    width={150}
                    height={85}
                />
            </Link>
        ),
        [ASSETS_URL, license],
    );

    const renderAnjLicense = useMemo(
        () => (
            <>
                <div
                    id={`anj-${license.anj_id}`}
                    data-anj-seal-id={license.anj_id}
                    data-anj-image-size="128"
                    data-anj-image-type="basic-small"
                />
                <Script
                    src={`https://${license.anj_id}.snippet.anjouangaming.org/anj-seal.js`}
                    onLoad={onAnjouanGamingScriptLoad}
                />
            </>
        ),
        [license, onAnjouanGamingScriptLoad],
    );

    return (
        <div className={styles.licenseLogo}>
            {license.apg_id && renderApgLicense}
            {license.gcb_id && renderGcbLicense}
            {license.anj_id && renderAnjLicense}
        </div>
    );
};

export default LicenseLogo;
