import { CasinoGameTag } from "@finbackoffice/enums";
import {
    CasinoSectionsStyleEnum,
    CasinoGameTypeEnum,
    ProvidersEnum,
    CasinoWidgetImgSrcEnum,
} from "@finbackoffice/fe-core";
import {
    CasinoScopesMap,
    ICasinoDataState,
    useCasinoGames,
    UseCasinoGamesHookOptions,
} from "@finbackoffice/site-core";
import dynamic from "next/dynamic";
import { FC, createContext, useMemo } from "react";
import { CasinoGamesRowVariantEnum } from "../base/games-style/row/CasinoGamesRow";

const CasinoGamesRow = dynamic(() => import("../base/games-style/row/CasinoGamesRow"), {
    loading: () => <div />,
    ssr: false,
});

type IProps = {
    id: string;
    style: CasinoSectionsStyleEnum;
    tag?: CasinoGameTag;
    gameTypes: CasinoGameTypeEnum[] | CasinoGameTypeEnum;
    options?: UseCasinoGamesHookOptions;
    provider?: ProvidersEnum | "All";
    isSlots?: boolean;
    label?: string | { title: string; sub_title: string }; // TODO remove in the future
    href?: string;
    wrapperClassName?: string;
    imgSrc?: CasinoWidgetImgSrcEnum;
};

export type ICasinoGamesContext = {
    id: string;
    casinoGamesState: ICasinoDataState;
    href?: string;
    label?: string | { title: string; sub_title: string };
    isSlots: boolean;
    imgSrc?: CasinoWidgetImgSrcEnum;
};

export const CasinoGamesContext = createContext<ICasinoGamesContext>(null as any);

const CasinoGames: FC<IProps> = ({
    style,
    id,
    tag,
    gameTypes,
    options,
    label,
    provider = "All",
    href,
    imgSrc,
}) => {
    const gameTypesMemo = useMemo(
        () => (typeof gameTypes === "string" ? [gameTypes] : gameTypes),
        [gameTypes],
    );
    const { casinoGamesState, setPagination, hasMore } = useCasinoGames(
        gameTypesMemo,
        tag ?? "",
        provider,
        options,
    );
    const isSlots = gameTypes === CasinoScopesMap.slots;

    const renderContent = useMemo(() => {
        switch (style) {
            case CasinoSectionsStyleEnum.Row:
                return (
                    <CasinoGamesRow
                        variant={CasinoGamesRowVariantEnum.Horizontal}
                        headerIconFilename={
                            gameTypes === CasinoScopesMap.slots ? "slots-icon" : "live-casino-icon"
                        }
                        tag={tag}
                    />
                );
            case CasinoSectionsStyleEnum.NarrowRow:
                return (
                    <CasinoGamesRow
                        headerIconFilename={
                            gameTypes === CasinoScopesMap.slots ? "slots-icon" : "live-casino-icon"
                        }
                        variant={CasinoGamesRowVariantEnum.Narrow}
                    />
                );
            default:
                return null;
        }
    }, [gameTypes, style, tag]);

    const value = useMemo(
        () => ({ id, casinoGamesState, setPagination, hasMore, isSlots, label, href, imgSrc }),
        [casinoGamesState, hasMore, id, isSlots, label, setPagination, href, imgSrc],
    );

    return <CasinoGamesContext.Provider value={value}>{renderContent}</CasinoGamesContext.Provider>;
};

export default CasinoGames;
