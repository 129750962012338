import { CasinoGameTag, Language } from "@finbackoffice/enums";
import {
    formatAppNameText,
    BonusPagesEnum,
    CasinoGameTypeEnum,
    CasinoLayoutEnum,
    IBonusPagesConfig,
    IRuntimeConfig,
} from "@finbackoffice/fe-core";
import { BrowserStorageIdsEnum, CasinoScopesMap } from "@finbackoffice/site-core";
import { DEFAULT_ROOT_LOCALE } from "@finbackoffice/site-server-core";
import { RouteNames, RouterQuery } from "./constants";

type GetCasinoGameImgSrcOptions = {
    showVideo?: boolean;
    imgVariant?: string;
    useAppName?: boolean;
    folder?: "narrow";
};

export const getCasinoGameImgSrc = (
    runtimeConfig: IRuntimeConfig,
    gameId: string,
    gameType: CasinoGameTypeEnum,
    tags: CasinoGameTag[],
    options?: GetCasinoGameImgSrcOptions,
) => {
    let gameImgId = gameId.replace("-mobile", "");
    let dir = `${runtimeConfig.ASSETS_URL}/common/casino`;
    let extention;

    if (
        [CasinoLayoutEnum.Compact, CasinoLayoutEnum.GameStore].includes(
            runtimeConfig.SITE_CONFIG.layouts._casino.type,
        )
    ) {
        extention = tags.includes(CasinoGameTag.VFX) ? "mp4" : "webp";
        dir += "/compact";

        if (CasinoScopesMap.slots.includes(gameType as CasinoGameTypeEnum)) {
            dir += "/slot-games";
        } else {
            dir += `/live-casino-games`;
        }

        if (options?.folder) {
            dir += `/${options.folder}`;
        }

        if (options?.showVideo) {
            if (options.imgVariant) {
                gameImgId += `_${options.imgVariant}`;
            }
            extention = "mp4";
        }
    }

    return `${dir}/${gameImgId}${
        options?.useAppName
            ? `_${formatAppNameText(runtimeConfig.COMMON_SITE_CONFIGS.appName)}`
            : ""
    }.${extention}`;
};

export const getCurrentLocale = (
    runtimeConfig: IRuntimeConfig,
    currentLocale: string = runtimeConfig.COMMON_SITE_CONFIGS.defaultLocale,
) => {
    if (currentLocale === DEFAULT_ROOT_LOCALE) {
        return runtimeConfig.COMMON_SITE_CONFIGS.defaultLocale;
    }
    return currentLocale as Language;
};

export function generateUUID() {
    if (typeof window === "undefined") {
        // server
        return "";
    }

    // browser
    const localStorageValue = localStorage.getItem(BrowserStorageIdsEnum.DEVICE_ID);
    if (localStorageValue) {
        return localStorageValue;
    }
    const uuid = crypto.randomUUID();
    localStorage.setItem(BrowserStorageIdsEnum.DEVICE_ID, uuid);
    return uuid;
}

export const getBonusesDefaultRoute = (config?: IBonusPagesConfig) => {
    let result = `${RouteNames.PROMOTIONS}/${RouterQuery.Casino}`;
    switch (config?.name) {
        case BonusPagesEnum.Referral:
            result = RouteNames.REFERRAL;
            break;
        case BonusPagesEnum.Cashback:
            result = RouteNames.CASHBACK;
            break;
        case BonusPagesEnum.Promotions:
            result = RouteNames.PROMOTIONS;
            break;
        case BonusPagesEnum.Casino:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Casino}`;
            break;
        case BonusPagesEnum.Casino2:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Casino2}`;
            break;
        case BonusPagesEnum.Promo2:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo2}`;
            break;
        case BonusPagesEnum.Promo3:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo3}`;
            break;
        case BonusPagesEnum.Promo4:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo4}`;
            break;
        case BonusPagesEnum.Promo5:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo5}`;
            break;
        case BonusPagesEnum.Promo6:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Promo6}`;
            break;
        default:
            result = `${RouteNames.PROMOTIONS}/${RouterQuery.Casino}`;
    }
    return result;
};
